    .select-items-container {
    .items-images-container {
        .image-container.first {
            @media (min-width: 1024px) {
                left: 0
            }
        }
        .image-container.second {
            @media (min-width: 1024px) {
                left: 25%;
            }
        }
        .image-container.third {
            @media (min-width: 1024px) {
                left: 62%;
                transform: translateX(-50%);
            }
        }
        .image-container.fourth {
            @media (min-width: 1024px) {
                right: 0%;
            }
        }
        .image-container.fifth {
            @media (min-width: 1024px) {
                right: -5%;
            }
        }
    }
}
.items-count-container {
    @media (min-width: 1024px) {
        padding: 0px 160px;
    }
}