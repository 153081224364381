// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
.invis {
  opacity: 0;
}
.custom-toast.Toastify__toast--success {
  background: #1b5e20; 
}
.custom-toast.Toastify__toast--warning {
  color: #000;
  background: #ffc107; 
}
textarea:focus, input:focus{
  outline: none;
}
.centered-box {
  @media (min-width: 1024px) {
    transform: translate(-50%, -50%)
  }
}
@media print {
  img {
    display: none;
  }
  .btn {
    display: none;
  }
}